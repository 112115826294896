$color1: #e9e9e9;
$color2: #707070;
$color3: #333333;
$color4: #ef5049;
$white: #ffffff;
$br4: 4px;
.createNewOrder {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .contain {
    border: 1px solid $color1;
    border-radius: $br4;
    margin: 0.5rem 0;

    table {
      position: relative;

      th,
      td {
        vertical-align: middle;
        img {
          &.img-thumbnail {
            max-width: inherit;
          }
        }

        &.product {
          b {
            display: block;
            max-width: 500px;
            white-space: break-spaces;
            min-width: 250px;
          }
          span {
            color: $color2;
          }
        }
        &.addCustomPrice {
          span {
            display: block;
          }
        }
        .form-control {
          &.qty {
            max-width: 85px;
          }
          &.customPrice {
            max-width: 125px;
          }
        }
        .customPriceCheckbox {
          gap: 0.25rem;
          .form-check-input {
            margin-top: 0;
            &:checked {
              background-color: $color3;
              border-color: $color3;
            }
          }
        }
        .actionBtn {
          &.delete {
            border: 1px solid $color3;
            border-radius: $br4;
            color: $color3;
            background-color: $white;
            padding: 0.15rem 0.75rem;
            &:hover {
              color: $color4;
              border: 1px solid $color4;
            }
          }
        }
      }
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  background: $color2;
  right: 0;
  left: 0;
  opacity: 0.1;
  text-align: center;
  img {
    width: 100px;
    height: 100px;
    transform: translate(-50%, 129%);
    position: inherit;
  }
}
.CrossText {
  text-decoration: line-through;
}
.customPriceTxtClr {
  color: #e9e9ef !important;
}
.customPriceTxtClrInvert {
  color: black !important;
}
.bbItemBg {
  background-color: #fbf5de;
}
