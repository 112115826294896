.my-class{
    background-color:beige;
}
.delivery_dt{
   
    border: 1px solid #bd4a4a;
    width: auto;
    display: inline-block;
    margin-right: 11px;
    padding: 2px 7px;
    border-radius: 3px;
    text-align: center;
    background: #ffd0d0;
    color: #801616;
    margin-left: 10px;
}
.QData{
    white-space: break-spaces;
    max-width: 250px;
    .createdBy{
        font-style: italic;
        color: green;
        font-weight: bold;
    }
}


